import { isFeatureActive } from 'src/ds4/api/featureFlag'
import { FEATURE_FLAGS } from 'src/ds4/config/constants'
import API, { API_URL } from 'src/ds4/api'
import { prepareProductSearchRequest } from '../helper'

export const getAttributesFromPimConnector = ({ limit, offset }) =>
  API.offersConnector.post(API_URL.ATTRIBUTE_CONNECTOR, {
    limit: limit,
    offset: offset,
  })

export const searchedAttributesFromPimConnector = ({ body }) => {
  return API.offersConnector.post(API_URL.PRODUCT_SEARCH, body)
}

export const getGroupTree = (type) => {
  return API.offersConnector.get(API_URL.GROUP_TREE(type))
}

export const getItems = (requestParams, type) => {
  const payload = prepareProductSearchRequest({ request: requestParams, type })
  return API.offersConnector.post(API_URL.PRODUCT_SEARCH, payload)
}

export const getItemIdsBySku = (sku) => {
  const isPimV1 = isFeatureActive({ flagName: FEATURE_FLAGS.PIM_V1 })
  if (isPimV1) {
    return API.pim.post(API_URL.ITEM_IDS_BY_SKU(), sku)
  } else {
    return API.offersConnector.post(API_URL.ITEM_IDS_BY_SKU(), sku)
  }
}
