import { createReducer } from 'src/ds4/store/utils'
import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import {
  ADD_TO_TEMP_SELECTED_CATEGORIES,
  ADD_TO_TEMP_SELECTED_COLLECTIONS,
  SET_PROMOTION,
  RESET_PROMOTION,
  RESET_PRODUCTS,
  SET_EXCLUSION,
  SET_ATTRIBUTE_ITEM_DETAILS,
  SET_FORM_VALUES,
  SET_PROMO_FORM_VALUES,
  ON_EXCLUDE_INCLUDE,
  SET_INCLUDE_OPERATOR,
  SET_EXCLUDE_OPERATOR,
  SET_INCLUDE_RULES,
  SET_EXCLUDE_RULES,
  BULK_SKU_CHECK,
  ON_SKU_DELETE,
  ON_SKUS_SELECT_ALL,
  UPDATE_ON_PRODUCTS,
  ON_CHECK_CAGTEGORY,
  ON_CHECK_COLLECTION,
  ON_CAGTEGORY_DELETE,
  ON_COLLECTION_DELETE,
  ON_ATTRIBUTE_CHECK,
  ON_ATTRIBUTE_DELETE,
  CLICK_ON_PROMOTION_TYPE,
  UPDATE_PROMO_TIERS,
  ON_CANCEL,
  SET_CATEGORIES_SKUS_COUNT,
  SET_COLLECTIONS_SKUS_COUNT,
  SET_PROMO_USAGE,
  SET_ALL_PRODUCTS_COUNT,
  SET_FINAL_ATTRIBUTE_ITEM_DETAILS,
  SET_TARGET_TYPE,
  SET_ANOTHER_TARGET_TYPE,
  DELETE_TARGET_TYPE,
  IS_FETCHING_ITEMS,
  SET_FINAL_CATEGORIES_SKUS_COUNT,
  SET_FINAL_COLLECTIONS_SKUS_COUNT,
  SET_FINAL_ATTRIBUTES_SKUS_COUNT,
  SET_FINAL_PRODUCTS_COUNT,
  RESET_PROMO_USAGE,
  SET_CUSTOMER_SEGMENT,
  SET_USER_SEGMENT,
  SET_EXCLUDE_CUSTOMER_SEGMENT_OPERATOR,
  SET_INCLUDE_CUSTOMER_SEGMENT_OPERATOR,
  SET_PROMOTION_LIMIT,
  SET_TRIGGER_VALIDATION,
  SET_EXCLUDE_CUSTOMER_SEGMENT,
  SET_IS_DS4,
  ON_CHECK_SKU,
  ON_ATTRIBUTE_RESET,
  SET_CATEGORIES_PATH,
  SET_COLLECTIONS_PATH,
  SET_PROMOTION_MESSAGES,
  SET_PROMOTION_MESSAGES_FORM_VALID,
  SET_PROXIMITY_MESSAGES,
  SET_PROMOTION_ADDITIONAL_ATTRIBUTES,
} from './actions'
import { ALL, PromotionRadioTypes, PromotionTypeOptions } from './constants'
import {
  getAllExtraFieldsFromData,
  getSelectedStackingType,
  isArrayEmpty,
} from './lib/helper'
import { updateSelectedSkus, getSelectedSkus } from './helper/sku'
import {
  onCheckCategory,
  getSelectedCategories,
  onCheckCollection,
  createCategoryPath,
} from './helper/categories'
import { onCheckAttributes, getSelectedAttributes } from './helper/attributes'
import { setTargetXInitialStateV2 } from 'src/ds4/features/promo/lib/productSelectionV2'
import { isStackable } from 'src/lib/constants/constants'

const targetTypesInitialState = getTargetTypesInitialState()

let PromotionLimitType = { Order: 'ORDER' }
const initialState = {
  isDS4: false,
  promotion: {
    title: '',
    note: '',
    promoCodes: [],
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    isExclusive: true,
    termsAndConditions: [
      {
        title: '',
        description: '',
      },
    ],
    channelIds: [],
  },
  promo: [],
  rules: [],
  restrictions: [],
  promoConfig: {
    promoTypes: [],
    promoKinds: [],
    promoRules: {},
    promoRestrictions: [],
    promoUnits: {},
  },
  explicitOption: 'isMultiple',
  selectedStackingOption: isStackable,
  selectedPriceListOptions: 'isSpecific',
  selectedEligiblePriceList: [],
  statusState: true,
  level: null,
  allSkusSelected: false,
  promotionRadioOptions: PromotionRadioTypes,
  selectedPromoTypeRadio: 'SKU(s)',
  promoTiers: [
    {
      id: 0,
      buyValue: '',
      tierDiscounts: [{
        discountType: '',
        discountOn: 'SKU',
        discountValue: '',
        discountValueIfDiscountExist: '',
        selectedShippingType: [],
        dynamicDiscount: false,
        shippingApplicableOn: '',
        selectedSortBy: ALL,
      }],
      typeWithValues: {
        dynamicDiscount: false,
        selectedPromotionType: PromotionTypeOptions[0].title || '',
        promotionTypeValue: '',
        promotionTypeValueIfDiscountExist: '',
        shippingPromotionTypeValue: '',
        shippingPromotionTypeValueIfDiscountExist: '',
        selectedShippingType: [],
        shippingApplicableOn: '',
        selectedSortBy: ALL,
        selectedShippingPromotionType: { id: 0, name: 'Percentage off' },
      },
    },
  ],
  selectedPriceRadio: 'sale-base-price',
  categoriesSkusCount: 0,
  promoUsage: [
    {
      id: 'PER_USER',
      title: 'Limit coupon use per customer',
      checked: false,
      value: '',
    },
    {
      id: 'SITE_WIDE',
      title: 'Limit coupon usage site wide',
      checked: false,
      value: '',
    },
  ],
  buyXSameSku: '',
  getYSameSku: '',
  targetType: 'targetX',
  selectedAdvancedOption: 'SAME_SKUS',
  categoriesPath: {},
  collectionsPath: {},
  userKind: 'All',
  isAdvanceOptionsOpen: false,
  ...targetTypesInitialState,
  customerSegment: [{ name: '', values: [], label: '' }],
  includeCustomerSegmentsOperator: 'AND',
  excludeCustomerSegmentsOperator: 'AND',
  excludeCustomerSegment: [],
  selectedUserSegment: null,
  promotionMessages: [],
  promotionMessagesFormValid: true,
  promotionLimit: {
    value: 0,
  },
}

const promoReducer = createReducer(initialState, {
  [ADD_TO_TEMP_SELECTED_CATEGORIES]: (state, action) => {
    const { targetType, categories, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}TempSelectedCategories`]: categories,
    }
  },
  [ADD_TO_TEMP_SELECTED_COLLECTIONS]: (state, action) => {
    const { targetType, collections, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}TempSelectedCollections`]: collections,
    }
  },
  [CLICK_ON_PROMOTION_TYPE]: (state, action) => {
    let typeInitialState = getTargetTypesInitialState('targetY')
    let buyOperator = ''
    let getType = ''
    let buyData = []
    let getData = {}
    let getWithMultiDiscount = []
    if (action.payload === 'Buy-Get') {
      typeInitialState = getTargetTypesInitialState('targetX')
      typeInitialState = {
        ...typeInitialState,
        ...addTargetTypeInitialState('setA'),
      }
      buyOperator = 'OR'
      buyData.push({
        minimumQuantity: '',
        set: 'A',
      })
      getType = 'SKU'
      getData = [
        {
          isAllSelected: false,
          getShippingType: [],
          getSelectedSkuSets: [],
          getMaxQuantity: '',
          getMinQuantity: '',
          getSkuSet: '',
          getDiscountType: {
            id: 0,
            name: 'Percentage off',
            label: 'Percentage off',
          },
          getDiscountSortBy: ALL,
          getDiscountAmount: '',
          getDiscountOn: { id: 'SKU', label: 'SKU' },
        },
      ]
      getWithMultiDiscount = [
        {
          getDiscountOn: { id: 'SKU', label: 'SKU' },
          subDiscounts: [
            {
              getDiscountType: {
                id: 0,
                name: 'Percentage off',
                label: 'Percentage off',
              },
              getDiscountSortBy: ALL,
              getMaxQuantity: '',
              getSkuSet: '',
              getSelectedSkuSets: [],
              getMinQuantity: '',
              isAllSelected: false,
              getDiscountAmount: '',
            },
          ],
          //this discount type/amount is used if there is no subdiscounts associated with a discount
          getDiscountType: {
            id: 0,
            name: 'Percentage off',
            label: 'Percentage off',
          },
          getDiscountAmount: '',
          getShippingType: [],
          getDiscountSortBy: ALL,
        },
      ]
    }

    return {
      ...state,
      promotion: {
        ...state.promotion,
        buyOperator: buyOperator,
        buyData: buyData,
        getData: getData,
        getType: getType,
        getWithMultiDiscount,
      },
      targetType: action.payload === 'Buy-Get' ? 'setA' : state.targetType,
      promotionRadioOptions: PromotionRadioTypes,
      selectedPromoTypeRadio: action.payload,
      // reset Advanced options
      buyXSameSku: initialState.buyXSameSku,
      getYSameSku: initialState.getYSameSku,
      ...typeInitialState,
      // reset promoTiers
      promoTiers: [
        {
          id: 0,
          buyValue: '',
          tierDiscounts: [{
            discountType: '',
            discountOn: 'SKU',
            discountValue: '',
            discountValueIfDiscountExist: '',
            selectedShippingType: [],
            dynamicDiscount: false,
            shippingApplicableOn: '',
            selectedSortBy: ALL,
          }],
          typeWithValues: {
            selectedPromotionType: PromotionTypeOptions[0].title || '',
            promotionTypeValue: '',
            promotionTypeValueIfDiscountExist: '',
            shippingPromotionTypeValue: '',
            shippingPromotionTypeValueIfDiscountExist: '',
            selectedSortBy: ALL,
            selectedShippingType: [],
            shippingApplicableOn: '',
            dynamicDiscount: state?.promoTiers[0]?.typeWithValues
              ? state.promoTiers[0].typeWithValues.dynamicDiscount
              : false,
            selectedShippingPromotionType: { id: 0, name: 'Percentage off' },
          },
        },
      ],
    }
  },
  [UPDATE_PROMO_TIERS]: (state, action) => {
    const { newTiers, oldTiers } = action.payload
    return {
      ...state,
      promoTiers: newTiers,
      promo: oldTiers || state.promo,
    }
  },

  [RESET_PRODUCTS]: (state) => {
    const typeInitialState = getTargetTypesInitialState()
    return {
      ...state,
      ...typeInitialState,
    }
  },

  [RESET_PROMOTION]: () => {
    return {
      ...initialState,
      promotion: {
        ...initialState.promotion,
        termsAndConditions: [
          {
            title: '',
            description: '',
          },
        ],
      },
      promoUsage: [
        {
          id: 'PER_USER',
          title: 'Limit coupon use per customer',
          checked: false,
          value: '',
        },
        {
          id: 'SITE_WIDE',
          title: 'Limit coupon usage site wide',
          checked: false,
          value: '',
        },
      ],
      promoTiers: [
        {
          id: 0,
          buyValue: '',
          tierDiscounts: [{
            discountType: '',
            discountOn: 'SKU',
            discountValue: '',
            discountValueIfDiscountExist: '',
            selectedShippingType: [],
            dynamicDiscount: false,
            shippingApplicableOn: '',
            selectedSortBy: ALL,
          }],
          typeWithValues: {
            selectedPromotionType: PromotionTypeOptions[0].title || '',
            dynamicDiscount: false,
            selectedSortBy: ALL,
            promotionTypeValue: '',
            promotionTypeValueIfDiscountExist: '',
            shippingPromotionTypeValue: '',
            shippingPromotionTypeValueIfDiscountExist: '',
            selectedShippingType: [],
            shippingApplicableOn: '',
            selectedShippingPromotionType: { id: 0, name: 'Percentage off' },
          },
        },
      ],
    }
  },
  [SET_EXCLUSION]: (state, action) => {
    const { extra } = action.payload
    const updatedTypeState =
      extra && extra.target && setTargetTypesInitialState(extra)
    return {
      ...state,
      ...updatedTypeState,
    }
  },
  [SET_PROMO_FORM_VALUES]: (state, action) => {
    return {
      ...state,
      promotion: {
        ...state.promotion,
        [action.payload.type]: action.payload.value,
      },
    }
  },
  [SET_CUSTOMER_SEGMENT]: (state, action) => {
    return returnStateWithPayload(state, action)
  },
  [SET_INCLUDE_CUSTOMER_SEGMENT_OPERATOR]: (state, action) => {
    return {
      ...state,
      includeCustomerSegmentsOperator: action.payload,
    }
  },
  [SET_EXCLUDE_CUSTOMER_SEGMENT_OPERATOR]: (state, action) => {
    return {
      ...state,
      excludeCustomerSegmentsOperator: action.payload,
    }
  },
  [SET_EXCLUDE_CUSTOMER_SEGMENT]: (state, action) => {
    return returnStateWithPayload(state, action)
  },
  [SET_USER_SEGMENT]: (state, action) => {
    return returnStateWithPayload(state, action)
  },
  [SET_FORM_VALUES]: (state, action) => {
    return returnStateWithPayload(state, action)
  },
  [SET_TARGET_TYPE]: (state, action) => {
    return {
      ...state,
      targetType: action.payload,
    }
  },
  [SET_ANOTHER_TARGET_TYPE]: (state, action) => {
    let typeInitialState = addTargetTypeInitialState(action.payload)
    return {
      ...state,
      targetType: action.payload,
      ...typeInitialState,
    }
  },
  [SET_PROMOTION_LIMIT]: (state, action) => {
    return {
      ...state,
      promotionLimit: action.payload.value,
    }
  },
  [SET_TRIGGER_VALIDATION]: (state, action) => {
    return {
      ...state,
      triggerValidation: action.payload.value,
    }
  },
  [DELETE_TARGET_TYPE]: (state, action) => {
    state = deleteTargetType(action.payload, state)
    return {
      ...state,
    }
  },
  [SET_PROMOTION]: (state, action) => {
    let { extra, level, isStackable, isAlwaysApplied, additionalAttributes } =
      action.payload
    let categoriesPath = {}
    let collectionsPath = {}

    if (!extra) {
      return getWithoutExtraFieldData({ state, action })
    }
    if (!extra.promoTiers) {
      extra = getAllExtraFieldsFromData(action.payload)
    }
    if (extra?.products?.categories) {
      categoriesPath = createCategoryPath(extra?.products?.categories)
    }
    if (extra?.products?.collections) {
      collectionsPath = createCategoryPath(extra?.products?.collections, true)
    }
    const updatedTypeState =
      extra && extra.target && setTargetTypesInitialState(extra)
    const {
      selectedUserSegment,
      customerSegment,
      excludeCustomerSegment,
      includeCustomerSegmentsOperator,
      excludeCustomerSegmentsOperator,
    } = transformSegment({
      promo: action.payload.promo,
    })
    return {
      ...state,
      promotion: {
        ...action.payload,
        buyOperator:
          extra.selectedPromoTypeRadio === 'Buy-Get'
            ? extra.buyOperator
            : action.payload.buyOperator,
        buyData:
          extra.selectedPromoTypeRadio === 'Buy-Get' ? extra.buyData : '',
        getData:
          extra.selectedPromoTypeRadio === 'Buy-Get' ? extra.getData : '',
        getType:
          extra.selectedPromoTypeRadio === 'Buy-Get' ? extra.getType : 'SKU',
        spendData:
          extra.selectedPromoTypeRadio === 'Buy-Get' ? extra.spendData : '',
        getWithMultiDiscount:
          extra.selectedPromoTypeRadio === 'Buy-Get'
            ? extra.getWithMultiDiscount
            : '',
      },
      categoriesPath,
      collectionsPath,
      rules: action.payload.rules,
      promo: action.payload.promo,
      restrictions: action.payload.rules,
      promoCodes: action.payload.promoCodes,
      title: action.payload.title,
      selectedPriceListOptions: extra.selectedPriceListOptions,
      selectedEligiblePriceList: action.payload.eligiblePriceList || [],
      statusState: action.payload.state,
      startDate: action.payload.startDate,
      note: action.payload.note,
      endDate: action.payload.endDate,
      promoTiers: extra.promoTiers?.length
        ? extra.promoTiers
        : state.promoTiers,
      selectedPromoTypeRadio: extra.selectedPromoTypeRadio,
      selectedPriceRadio: extra.selectedPriceRadio,
      buyXSameSku: extra.buyXSameSku,
      getYSameSku: extra.getYSameSku,
      targetType:
        extra.target && extra.target.types && extra.target.types['targetY']
          ? 'targetY'
          : 'targetX',
      userKind: extra.userKind,
      ...updatedTypeState,
      explicitOption: extra.selectedExplicitOption
        ? extra.selectedExplicitOption
        : 'isMultiple',
      selectedStackingOption: getSelectedStackingType({
        stackable: isStackable,
        isAlwaysApplied,
        level,
      }),
      promoUsage:
        action.payload.limits && action.payload.limits.length > 0
          ? setPromoUsage(action.payload.limits, state.promoUsage)
          : state.promoUsage,
      promotionLimit:
        action.payload.limits &&
        action.payload.limits?.filter(
          (item) => item.kind === PromotionLimitType.Order
        )[0],
      customerSegment,
      excludeCustomerSegment,
      includeCustomerSegmentsOperator,
      excludeCustomerSegmentsOperator,
      selectedUserSegment: selectedUserSegment,
      proximityMessages: action.payload.proximityMessages,
      additionalAttributes: !isArrayEmpty(additionalAttributes)
        ? additionalAttributes
        : undefined,
    }
  },
  [ON_SKUS_SELECT_ALL]: (state, action) => {
    return { ...state, allSkusSelected: action.payload }
  },
  [SET_INCLUDE_OPERATOR]: (state, action) => {
    const { targetType, condition } = action.payload
    return {
      ...state,
      [`${targetType}IncludeCondition`]: condition,
    }
  },
  [SET_EXCLUDE_OPERATOR]: (state, action) => {
    const { targetType, condition } = action.payload
    return {
      ...state,
      [`${targetType}ExcludeCondition`]: condition,
    }
  },
  [SET_INCLUDE_RULES]: (state, action) => {
    const { targetType, rules } = action.payload
    return {
      ...state,
      [`${targetType}IncludeSelectedRules`]: rules,
    }
  },
  [SET_EXCLUDE_RULES]: (state, action) => {
    const { targetType, rules } = action.payload
    return {
      ...state,
      [`${targetType}ExcludeSelectedRules`]: rules,
    }
  },

  [ON_EXCLUDE_INCLUDE]: (state, action) => {
    return excludeInclude({ state, targetDetail: action.payload })
  },

  [ON_CHECK_SKU]: (state, action) => {
    const {
      sku,
      notToUpdateCount,
      isSelectAll,
      targetType,
      operator = '',
    } = action.payload
    let selectedSkusTree = state[`${targetType}${operator}TempSelectedSkusTree`]
    let selectedSkusCount =
      state[`${targetType}${operator}TempSelectedSkusCount`] || 0
    const { updatedTempSkus, updatedCount } = updateSelectedSkus({
      selectedSkus: selectedSkusTree,
      sku,
      notToUpdateCount,
      selectedSkusCount,
      isSelectAll,
    })
    return {
      ...state,
      allSkusSelected: isSelectAll ? isSelectAll : false,
      [`${targetType}${operator}TempSelectedSkusTree`]:
        cloneDeep(updatedTempSkus),
      [`${targetType}${operator}TempSelectedSkusCount`]: updatedCount,
    }
  },
  [BULK_SKU_CHECK]: (state, action) => {
    const {
      skus,
      notToUpdateCount,
      isSelectAll,
      targetType,
      operator = '',
    } = action.payload
    let selectedSkusTree =
      state[`${targetType}${operator}TempSelectedSkusTree`] || {}
    let selectedSkusCount =
      state[`${targetType}${operator}TempSelectedSkusCount`] || 0
    skus.forEach((sku, index) => {
      if (
        !selectedSkusTree[sku._id] &&
        (!selectedSkusTree[sku.parent] ||
          !selectedSkusTree[sku.parent][sku._id])
      ) {
        const { updatedTempSkus, updatedCount } = updateSelectedSkus({
          selectedSkus: selectedSkusTree,
          sku,
          notToUpdateCount,
          selectedSkusCount,
          isSelectAll,
        })
        selectedSkusTree = updatedTempSkus
        selectedSkusCount = updatedCount
      }
      if (selectedSkusTree[sku.parent]) {
        const prentProperties = [
          '_id',
          'parent',
          'itemId',
          'sku',
          'title',
          'images',
        ]
        prentProperties.forEach((property) => {
          delete selectedSkusTree[sku.parent][property]
        })
        if (skus.length - 1 == index) {
          selectedSkusCount = skus.length
        }
      }
    })
    return {
      ...state,
      allSkusSelected: isSelectAll ? isSelectAll : false,
      [`${targetType}${operator}TempSelectedSkusTree`]: { ...selectedSkusTree },
      [`${targetType}${operator}TempSelectedSkusCount`]: selectedSkusCount,
    }
  },

  [SET_FINAL_ATTRIBUTE_ITEM_DETAILS]: (state, action) => {
    const { targetType } = action.payload
    return {
      ...state,
      [`${targetType}FinalAttributeItemsDetails`]:
        state[`${targetType}AttributeItemsDetails`] || {},
    }
  },

  [UPDATE_ON_PRODUCTS]: (state, action) => {
    const { key, targetType, operator = '' } = action.payload
    const filteredProducts = state[`${targetType}${operator}Products`]?.length
      ? state[`${targetType}${operator}Products`].filter(
          (child) => child.key !== key
        )
      : []
    const isAttributes = key === 'Attributes'
    const isSkus = key === 'SKUs'
    const isCategories = key === 'Categories'
    const isCollections = key === 'Collections'
    return {
      ...state,
      [`${targetType}${operator}Products`]: filteredProducts,
      [`${targetType}${operator}TempSelectedAttributesTree`]: isAttributes
        ? {}
        : state[`${targetType}${operator}TempSelectedAttributesTree`],
      [`${targetType}${operator}FinalSelectedAttributesTree`]: isAttributes
        ? {}
        : state[`${targetType}${operator}FinalSelectedAttributesTree`],
      [`${targetType}${operator}AttributeItemsDetails`]: isAttributes
        ? {}
        : { ...state[`${targetType}${operator}AttributeItemsDetails`] },
      [`${targetType}${operator}FinalAttributeItemsDetails`]: isAttributes
        ? {}
        : { ...state[`${targetType}${operator}FinalAttributeItemsDetails`] },
      [`${targetType}${operator}SelectedAttributes`]: isAttributes
        ? []
        : state[`${targetType}${operator}SelectedAttributes`],
      [`${targetType}${operator}TempSelectedSkusTree`]: isSkus
        ? {}
        : state[`${targetType}${operator}TempSelectedSkusTree`],
      [`${targetType}${operator}FinalSelectedSkusTree`]: isSkus
        ? {}
        : state[`${targetType}${operator}FinalSelectedSkusTree`],
      [`${targetType}${operator}SelectedSkus`]: isSkus
        ? []
        : state[`${targetType}${operator}SelectedSkus`],
      [`${targetType}${operator}TempSelectedSkusCount`]: isSkus
        ? 0
        : state[`${targetType}${operator}TempSelectedSkusCount`],
      [`${targetType}${operator}FinalSelectedSkusCount`]: isSkus
        ? 0
        : state[`${targetType}${operator}FinalSelectedSkusCount`],
      [`${targetType}${operator}TempSelectedCategories`]: isCategories
        ? []
        : cloneDeep(state[`${targetType}${operator}TempSelectedCategories`]),
      [`${targetType}${operator}FinalSelectedCategories`]: isCategories
        ? []
        : cloneDeep(state[`${targetType}${operator}FinalSelectedCategories`]),
      [`${targetType}${operator}CategoriesSkusCount`]: isCategories
        ? 0
        : state[`${targetType}${operator}CategoriesSkusCount`],
      [`${targetType}${operator}FinalCategoriesSkusCount`]: isCategories
        ? 0
        : state[`${targetType}${operator}FinalCategoriesSkusCount`],
      [`${targetType}${operator}TempSelectedCollections`]: isCollections
        ? []
        : cloneDeep(state[`${targetType}${operator}TempSelectedCollections`]),
      [`${targetType}${operator}FinalSelectedCollections`]: isCollections
        ? []
        : cloneDeep(state[`${targetType}${operator}FinalSelectedCollections`]),
      [`${targetType}${operator}CollectionsSkusCount`]: isCollections
        ? 0
        : state[`${targetType}${operator}CollectionsSkusCount`],
      [`${targetType}${operator}FinalCollectionsSkusCount`]: isCollections
        ? 0
        : state[`${targetType}${operator}FinalCollectionsSkusCount`],
    }
  },
  [ON_CHECK_CAGTEGORY]: (state, action) => {
    const { targetType, id, operator = '' } = action.payload
    const updatedSelectedCategories = onCheckCategory({
      id,
      categoryState: {
        ...state[`${targetType}${operator}TempSelectedCategories`],
      },
      categoriesPath: state.categoriesPath,
    })
    return {
      ...state,
      [`${targetType}${operator}TempSelectedCategories`]: cloneDeep(
        updatedSelectedCategories
      ),
    }
  },

  [ON_CHECK_COLLECTION]: (state, action) => {
    const { targetType, id, operator = '' } = action.payload
    const updatedSelectedCategories = onCheckCollection({
      id,
      collectionState: {
        ...state[`${targetType}${operator}TempSelectedCollections`],
      },
      collectionsPath: state.collectionsPath,
    })
    return {
      ...state,
      [`${targetType}${operator}TempSelectedCollections`]: cloneDeep(
        updatedSelectedCategories
      ),
    }
  },

  [SET_CATEGORIES_PATH]: (state, action) => {
    return {
      ...state,
      categoriesPath: { ...state.categoriesPath, ...action.payload },
    }
  },

  [SET_COLLECTIONS_PATH]: (state, action) => {
    return {
      ...state,
      collectionsPath: { ...state.collectionsPath, ...action.payload },
    }
  },

  [ON_CAGTEGORY_DELETE]: (state, action) => {
    const { id, targetType, operator = '' } = action.payload
    const updatedSelectedCategories = onCheckCategory({
      id,
      categoryState: {
        ...state[`${targetType}${operator}TempSelectedCategories`],
      },
      categoriesPath: state.categoriesPath,
    })
    let selectedCategories = getSelectedCategories({
      categories: updatedSelectedCategories,
    })
    let catValues = {
      key: 'Categories',
      values: selectedCategories,
      count: selectedCategories.length,
    }

    return {
      ...state,
      [`${targetType}${operator}TempSelectedCategories`]: cloneDeep(
        updatedSelectedCategories
      ),
      [`${targetType}${operator}FinalSelectedCategories`]: cloneDeep(
        updatedSelectedCategories
      ),
      [`${targetType}${operator}Products`]: updateProducts({
        products: state[`${targetType}${operator}Products`],
        type: 'Categories',
        value: catValues,
      }),
    }
  },

  [ON_COLLECTION_DELETE]: (state, action) => {
    const { id, targetType, operator = '' } = action.payload
    const updatedSelectedCollections = onCheckCollection({
      id,
      collectionState: {
        ...state[`${targetType}${operator}TempSelectedCollections`],
      },
      collectionsPath: state.collectionsPath,
    })
    let selectedCollections = getSelectedCategories({
      categories: updatedSelectedCollections,
    })
    let catValues = {
      key: 'Collections',
      values: selectedCollections,
      count: selectedCollections.length,
    }
    return {
      ...state,
      [`${targetType}${operator}TempSelectedCollections`]: cloneDeep(
        updatedSelectedCollections
      ),
      [`${targetType}${operator}FinalSelectedCollections`]: cloneDeep(
        updatedSelectedCollections
      ),
      [`${targetType}${operator}Products`]: updateProducts({
        products: state[`${targetType}${operator}Products`],
        type: 'Collections',
        value: catValues,
      }),
    }
  },

  [ON_ATTRIBUTE_CHECK]: (state, action) => {
    const {
      attribute,
      value,
      isValue,
      targetType,
      operator = '',
    } = action.payload
    let selectedAttributesTree =
      state[`${targetType}${operator}TempSelectedAttributesTree`] || {}
    const updatedSelectedAttributes = onCheckAttributes({
      selectedAttributes: selectedAttributesTree,
      attribute,
      value,
      isValue,
      operator,
    })
    let selectedAttributes = getSelectedAttributes({
      attributes: updatedSelectedAttributes,
    })
    return {
      ...state,
      [`${targetType}${operator}TempSelectedAttributes`]: selectedAttributes,
      [`${targetType}${operator}TempSelectedAttributesTree`]:
        updatedSelectedAttributes,
    }
  },

  [ON_ATTRIBUTE_DELETE]: (state, action) => {
    const { value, id, name, targetType, operator = '' } = action.payload
    let selectedAttributesTree =
      state[`${targetType}${operator}TempSelectedAttributesTree`]
    const updatedSelectedAttributes = onCheckAttributes({
      selectedAttributes: selectedAttributesTree,
      attribute: { id, name },
      value,
      isValue: true,
    })
    let selectedAttributes = getSelectedAttributes({
      attributes: updatedSelectedAttributes,
    })
    let attributeValues = {
      key: 'Attributes',
      values: selectedAttributes,
      count: selectedAttributes.length,
    }
    let products = state[`${targetType}${operator}Products`]
    products = updateProducts({
      products,
      type: 'Attributes',
      value: attributeValues,
    })
    return {
      ...state,
      [`${targetType}${operator}SelectedAttributes`]: selectedAttributes,
      [`${targetType}${operator}TempSelectedAttributes`]: selectedAttributes,
      [`${targetType}${operator}TempSelectedAttributesTree`]:
        updatedSelectedAttributes,
      [`${targetType}${operator}FinalSelectedAttributesTree`]:
        updatedSelectedAttributes,
      [`${targetType}${operator}Products`]: products,
    }
  },

  [ON_SKU_DELETE]: (state, action) => {
    const { sku, notToUpdateCount, targetType, operator = '' } = action.payload
    let selectedSkusTree = state[`${targetType}${operator}TempSelectedSkusTree`]
    const { updatedTempSkus, updatedCount } = updateSelectedSkus({
      selectedSkus: selectedSkusTree,
      sku,
      notToUpdateCount,
      selectedSkusCount: state[`${targetType}${operator}TempSelectedSkusCount`],
    })
    let selectedSkus = getSelectedSkus({ skus: updatedTempSkus })
    let skuValues = {
      key: 'SKUs',
      values: selectedSkus,
      count: selectedSkus.length,
    }
    return {
      ...state,
      [`${targetType}${operator}TempSelectedSkusTree`]: { ...updatedTempSkus },
      [`${targetType}${operator}TempSelectedSkusCount`]: updatedCount,
      [`${targetType}${operator}FinalSelectedSkusCount`]: updatedCount,
      [`${targetType}${operator}FinalSelectedSkusTree`]: { ...updatedTempSkus },
      [`${targetType}${operator}SelectedSkus`]: selectedSkus,
      [`${targetType}${operator}Products`]: updateProducts({
        products: state[`${targetType}${operator}Products`],
        type: 'SKUs',
        value: skuValues,
      }),
    }
  },

  [SET_ATTRIBUTE_ITEM_DETAILS]: (state, action) => {
    const { itemDetails, targetType, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}AttributeItemsDetails`]: itemDetails || {},
    }
  },

  [ON_CANCEL]: (state, action) => {
    const { targetType, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}TempSelectedAttributesTree`]: {
        ...state[`${targetType}${operator}FinalSelectedAttributesTree`],
      },
      [`${targetType}${operator}TempSelectedCategories`]: cloneDeep(
        state[`${targetType}${operator}FinalSelectedCategories`]
      ),
      [`${targetType}${operator}TempSelectedSkus`]: {
        ...state[`${targetType}${operator}FinalSelectedSkus`],
      },
      [`${targetType}${operator}TempCategoriesSkusCount`]:
        state[`${targetType}${operator}FinalCategoriesSkusCount`],
      [`${targetType}${operator}TempSelectedSkusCount`]:
        state[`${targetType}${operator}FinalSelectedSkusCount`],
    }
  },
  [SET_CATEGORIES_SKUS_COUNT]: (state, action) => {
    const { count, targetType, operator } = action.payload
    return {
      ...state,
      [`${targetType}${operator}TempCategoriesSkusCount`]: count,
    }
  },
  [SET_COLLECTIONS_SKUS_COUNT]: (state, action) => {
    const { count, targetType, operator } = action.payload
    return {
      ...state,
      [`${targetType}${operator}TempCollectionsSkusCount`]: count,
    }
  },
  [SET_FINAL_CATEGORIES_SKUS_COUNT]: (state, action) => {
    const { targetType, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}FinalCategoriesSkusCount`]:
        state[`${targetType}${operator}TempCategoriesSkusCount`],
    }
  },
  [SET_FINAL_COLLECTIONS_SKUS_COUNT]: (state, action) => {
    const { targetType, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}FinalCollectionsSkusCount`]:
        state[`${targetType}${operator}TempCollectionsSkusCount`],
    }
  },
  [SET_FINAL_ATTRIBUTES_SKUS_COUNT]: (state, action) => {
    const { targetType, operator = '' } = action.payload
    const attributeItemsDetails =
      state[`${targetType}${operator}AttributeItemsDetails`]
    const attrSkusCount =
      attributeItemsDetails && Object.keys(attributeItemsDetails)?.length > 0
        ? attributeItemsDetails?.count || attributeItemsDetails.totalProducts
        : 0
    return {
      ...state,
      [`${targetType}${operator}FinalAttributesSkusCount`]: attrSkusCount,
    }
  },
  [SET_PROMO_USAGE]: (state, action) => {
    return {
      ...state,
      promoUsage: action.payload,
    }
  },
  [SET_PROMOTION_MESSAGES]: (state, action) => {
    return returnStateWithPayload(state, {
      payload: {
        type: 'promotionMessages',
        value: action.payload,
      },
    })
  },
  [SET_PROMOTION_ADDITIONAL_ATTRIBUTES]: (state, action) => {
    return returnStateWithPayload(state, {
      payload: {
        type: 'additionalAttributes',
        value: action.payload,
      },
    })
  },
  [SET_PROXIMITY_MESSAGES]: (state, action) => {
    return returnStateWithPayload(state, {
      payload: {
        type: 'proximityMessages',
        value: action.payload,
      },
    })
  },
  [SET_PROMOTION_MESSAGES_FORM_VALID]: (state, action) => {
    return returnStateWithPayload(state, {
      payload: {
        type: 'promotionMessagesFormValid',
        value: action.payload,
      },
    })
  },
  [SET_ALL_PRODUCTS_COUNT]: (state, action) => {
    const { count, targetType, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}TempProductsCount`]: count || 0,
    }
  },
  [SET_FINAL_PRODUCTS_COUNT]: (state, action) => {
    const { targetType, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}FinalProductsCount`]:
        state[`${targetType}${operator}TempProductsCount`],
    }
  },
  [IS_FETCHING_ITEMS]: (state, action) => {
    return {
      ...state,
      isLoading: action.payload,
    }
  },
  [RESET_PROMO_USAGE]: (state) => {
    return {
      ...state,
      promoUsage: initialState.promoUsage,
    }
  },
  [SET_IS_DS4]: (state, action) => {
    return { ...state, isDS4: action.payload }
  },
  [ON_ATTRIBUTE_RESET]: (state, action) => {
    const { targetType, operator = '' } = action.payload
    return {
      ...state,
      [`${targetType}${operator}TempSelectedAttributesTree`]: {},
      [`${targetType}${operator}TempSelectedAttributes`]: [],
    }
  },
})

const updateProducts = ({ products = [], type, value }) => {
  let isKeyPresent = false
  let updatedProducts = []
  products?.forEach((product) => {
    if (product.key === type) {
      isKeyPresent = true
      if (value.values && value.values.length > 0) {
        updatedProducts.push(value)
      }
    } else {
      updatedProducts.push(product)
    }
  })
  if (!isKeyPresent && value.values.length > 0) {
    updatedProducts.push(value)
  }
  return updatedProducts
}

const getSegmentOperator = (segments, promo) => {
  let segmentOperator = 'AND'
  if (!segments) return segmentOperator

  if (segments.length === promo?.length) {
    segmentOperator = 'OR'
  }
  return segmentOperator
}

export const transformSegment = ({ promo }) => {
  const conditions = promo
    ?.map((items) => items?.condition)
    ?.flat()
    ?.map((item) => item?.conditions)
    ?.flat()

  const extractSegment = conditions?.filter(
    (condition) =>
      condition?.key === 'USER_SEGMENT' && condition?.operator === 'IN'
  )
  const extractExcludeSegment = conditions?.filter(
    (condition) =>
      condition?.key === 'USER_SEGMENT' && condition?.operator === 'NOT_IN'
  )

  let includeCustomerSegmentsOperator = getSegmentOperator(
    extractSegment,
    promo
  )

  let excludeCustomerSegmentsOperator = getSegmentOperator(
    extractExcludeSegment,
    promo
  )

  let selectedUserSegment = extractSegment[0]?.key || ''

  const extractSegmentValues = (extractedSegment) => {
    let segmentsMap = extractedSegment?.reduce((accumulator, segment) => {
      let segmentValuesMap = segment.value?.reduce?.((map, item) => {
        map[item.segmentId] = {
          ...item,
          label: item.name,
          defaultValue: item.value,
          isError: false,
        }

        return map
      }, {})
      accumulator = { ...accumulator, ...segmentValuesMap }
      return accumulator
    }, [])
    return Object.values(segmentsMap)
  }
  const excludeCustomerSegment = extractSegmentValues(extractExcludeSegment)

  if (extractSegment[0]?.value === '*') {
    return {
      selectedUserSegment: 'All',
      customerSegment: [{ name: '', values: [], label: '' }],
      excludeCustomerSegment,
      includeCustomerSegmentsOperator,
      excludeCustomerSegmentsOperator,
    }
  } else {
    const customerSegment = extractSegmentValues(extractSegment)

    return {
      selectedUserSegment: selectedUserSegment,
      customerSegment: customerSegment,
      excludeCustomerSegment,
      includeCustomerSegmentsOperator,
      excludeCustomerSegmentsOperator,
    }
  }
}

const excludeInclude = ({ state, targetDetail }) => {
  const { type, operator = '' } = targetDetail
  const skus = { ...state[`${type}${operator}TempSelectedSkusTree`] }
  const categories = { ...state[`${type}${operator}TempSelectedCategories`] }
  const collections = { ...state[`${type}${operator}TempSelectedCollections`] }
  const attributes = {
    ...state[`${type}${operator}TempSelectedAttributesTree`],
  }
  const attributeItemsDetails = state[`${type}${operator}AttributeItemsDetails`]
  const categoriesSkusCount = state[`${type}${operator}TempCategoriesSkusCount`]
  const collectionsSkusCount =
    state[`${type}${operator}TempCollectionsSkusCount`]
  const selectedSkusCount = state[`${type}${operator}TempSelectedSkusCount`]
  const totalCount = state[`${type}TempProductsCount`]
  let products = state[`${type}${operator}Products`]

  let attrSkusCount =
    attributeItemsDetails && Object.keys(attributeItemsDetails)?.length > 0
      ? attributeItemsDetails?.count || attributeItemsDetails.totalProducts
      : 0
  let selectedSkus = getSelectedSkus({ skus })
  let skuValues = {
    key: 'SKUs',
    values: selectedSkus,
    count: selectedSkus.length,
  }
  products = updateProducts({
    products,
    type: 'SKUs',
    value: skuValues,
    operator,
  })

  //get Selected Categories
  let selectedCategories = getSelectedCategories({ categories })
  selectedCategories = uniqBy(selectedCategories, 'id')

  let categoriesValues = {
    key: 'Categories',
    values: selectedCategories,
    count: selectedCategories.length,
    operator,
  }
  products = updateProducts({
    products,
    type: 'Categories',
    value: categoriesValues,
    operator,
  })

  //get selected collections
  let selectedCollections = getSelectedCategories({ categories: collections })
  selectedCollections = uniqBy(selectedCollections, 'id')

  let collectionsValues = {
    key: 'Collections',
    values: selectedCollections,
    count: selectedCollections.length,
    operator,
  }
  products = updateProducts({
    products,
    type: 'Collections',
    value: collectionsValues,
    operator,
  })

  // get Selected Attributes
  let selectedAttributes = getSelectedAttributes({ attributes })
  let attributeValues = {
    key: 'Attributes',
    values: selectedAttributes,
    count: selectedAttributes.length,
  }
  products = updateProducts({
    products,
    type: 'Attributes',
    value: attributeValues,
  })
  return {
    ...state,
    [`${type}${operator}SelectedSkus`]: selectedSkus,
    [`${type}${operator}SelectedCategories`]: selectedCategories,
    [`${type}${operator}SelectedCollections`]: selectedCollections,
    [`${type}${operator}TempSelectedSkusTree`]: cloneDeep(skus),
    [`${type}${operator}FinalSelectedSkusTree`]: cloneDeep(skus),
    [`${type}${operator}SelectedAttributes`]: selectedAttributes,
    [`${type}${operator}FinalSelectedCategories`]: cloneDeep(categories),
    [`${type}${operator}FinalSelectedCollections`]: cloneDeep(collections),
    [`${type}${operator}TempSelectedCategories`]: cloneDeep(categories),
    [`${type}${operator}TempSelectedCollections`]: cloneDeep(collections),
    [`${type}${operator}FinalSelectedAttributesTree`]: attributes,
    [`${type}${operator}Products`]: products,
    [`${type}${operator}FinalAttributeItemsDetails`]: attributeItemsDetails,
    [`${type}${operator}FinalCategoriesSkusCount`]: categoriesSkusCount,
    [`${type}${operator}FinalAttributesSkusCount`]: attrSkusCount,
    [`${type}${operator}FinalCollectionsSkusCount`]: collectionsSkusCount,
    [`${type}${operator}FinalSelectedSkusCount`]: selectedSkusCount,
    [`${type}${operator}FinalProductsCount`]: totalCount,
  }
}

function addTargetTypeInitialState(type) {
  return {
    [`${type}SelectedSkus`]: [],
    [`${type}SelectedAttributes`]: [],
    [`${type}TempSelectedCategories`]: {},
    [`${type}FinalSelectedCategories`]: {},
    [`${type}TempSelectedSkusTree`]: {},
    [`${type}FinalSelectedSkusTree`]: {},
    [`${type}TempSelectedAttributesTree`]: {},
    [`${type}FinalSelectedAttributesTree`]: {},
    [`${type}TempSelectedSkusCount`]: 0,
    [`${type}AttributeItemsDetails`]: {},
    [`${type}FinalAttributeItemsDetails`]: {},
    [`${type}SelectedSkus`]: [],
    [`${type}TempSelectedSkusTree`]: {},
    [`${type}TempSelectedSkusCount`]: 0,
    [`${type}FinalSelectedSkusCount`]: 0,
    [`${type}TempCategoriesSkusCount`]: 0,
    [`${type}FinalCategoriesSkusCount`]: 0,
    [`${type}TempCollectionsSkusCount`]: 0,
    [`${type}FinalCollectionsSkusCount`]: 0,
    [`${type}Products`]: [],
  }
}

function deleteTargetType(type, state) {
  Object.keys(state).forEach((key) => {
    if (key.includes(type)) {
      delete state[key]
    }
  })
  return state
}

function getTargetTypesInitialState(targetType) {
  let targetsInitalState = {}
  const targetTypes = ['targetX', 'exclusion']
  targetTypes.forEach((type) => {
    targetsInitalState[type] = {
      [`${type}IncludeSelectedRules`]: [],
      [`${type}ExcludeSelectedRules`]: [],
    }
  })
  return targetType
    ? targetsInitalState[targetType]
    : {
        ...targetsInitalState['targetX'],
        ...targetsInitalState['exclusion'],
      }
}

const setPromoUsage = (promoUsage, defaultPromoUsage) => {
  return defaultPromoUsage.map((el) => {
    let limit = { ...el }
    promoUsage.forEach((e) => {
      if (el.id === e.kind) {
        let checked = e.value > 0 ? true : false
        limit = {
          ...limit,
          checked: checked,
          value: e.value === 0 ? '' : e.value,
        }
      }
    })
    return limit
  })
}

const returnStateWithPayload = (state, action) => {
  return {
    ...state,
    [action.payload.type]: action.payload.value,
  }
}

const getWithoutExtraFieldData = ({ state, action }) => {
  return {
    ...state,
    ...action.payload,
    promotion: action.payload,
    restrictions: action.payload.rules,
    statusState: action.payload.state,
    startDate: action.payload.startDate,
    selectedEligiblePriceList: action.payload.eligiblePriceList || [],
    promoUsage:
      action.payload.limits && action.payload.limits.length > 0
        ? setPromoUsage(action.payload.limits, state.promoUsage)
        : state.promoUsage,
  }
}
export const setTargetTypesInitialState = (extra) => {
  let updatedState = {}
  const {
    target: { types },
  } = extra
  for (let type in types) {
    if (type === 'exclusion') {
      const skusTree = types[type].selectedSkus ? types[type].selectedSkus : {}
      const attrItemsDetails = types[type].attributesItemDetails
        ? types[type].attributesItemDetails
        : {}
      const categoriesTree = types[type].selectedCategories
        ? types[type].selectedCategories
        : {}
      const collectionsTree = types[type].selectedCollections
        ? types[type].selectedCollections
        : {}
      const attributesTree = types[type].selectedAttributesTree
        ? types[type].selectedAttributesTree
        : {}

      const selectedAttributes = types[type].selectedAttributes || []
      const categoriesPath = types[type]?.categoriesPath || {}
      const collectionsPath = types[type]?.collectionsPath || {}
      const products = types[type].products ? types[type].products : []
      const radioId = types[type].radioId
        ? types[type].radioId
        : 'INCLUDE_PRODUCTS'
      const skusCount = types[type].skusCount
      const totalProductsCount = types[type].totalProductsCount
      const categoriesSkusCount = types[type].categoriesSkusCount
      const collectionsSkusCount = types[type].collectionsSkusCount
      const attributesSkusCount = types[type].attributesSkusCount
      updatedState = {
        ...updatedState,
        categoriesPath,
        collectionsPath,
        [`${type}TempSelectedSkusTree`]: { ...skusTree },
        [`${type}FinalSelectedSkusTree`]: { ...skusTree },
        [`${type}TempSelectedCategories`]: { ...categoriesTree },
        [`${type}FinalSelectedCategories`]: { ...categoriesTree },
        [`${type}TempSelectedCollections`]: { ...collectionsTree },
        [`${type}FinalSelectedCollections`]: { ...collectionsTree },
        [`${type}TempSelectedAttributesTree`]: { ...attributesTree },
        [`${type}FinalSelectedAttributesTree`]: { ...attributesTree },
        [`${type}Products`]: products,
        [`${type}TempSelectedSkusCount`]: skusCount,
        [`${type}FinalSelectedSkusCount`]: skusCount,
        [`${type}PromoType`]:
          radioId === 'EXCLUDE_PRODUCTS' ? 'exclude' : 'include',
        [`${type}RadioId`]: radioId,
        [`${type}FinalProductsCount`]: totalProductsCount,
        [`${type}TempProductsCount`]: totalProductsCount,
        [`${type}TempCategoriesSkusCount`]: categoriesSkusCount,
        [`${type}FinalCategoriesSkusCount`]: categoriesSkusCount,
        [`${type}TempCollectionsSkusCount`]: collectionsSkusCount,
        [`${type}FinalCollectionsSkusCount`]: collectionsSkusCount,
        [`${type}attributesSkusCount`]: attributesSkusCount,
        [`${type}FinalAttributesSkusCount`]: attributesSkusCount,
        [`${type}SelectedSkus`]: getSelectedSkus({ skus: { ...skusTree } }),
        [`${type}SelectedAttributes`]: selectedAttributes,
        [`${type}FinalAttributeItemsDetails`]: { ...attrItemsDetails },
      }
    } else {
      updatedState = {
        ...updatedState,
        ...setTargetXInitialStateV2({ extra, type }),
      }
    }
  }
  return updatedState
}

export default promoReducer
