export const PROMO_COUPON_LISTING_DATE_FORMAT = 'MMM d, yyyy'

export const CREATE_PERMISSIONS = {
  IMPORT_PRICES: 'copilot:v1:offers.prices.import:create',
  CREATE_PRICES: 'copilot:v1:offers.prices:create',
  CREATE_PROMOTIONS: 'copilot:v1:offers.promotions:create',
  CREATE_COUPONS: 'copilot:v1:offers.coupons:create',
  CREATE_GLOBAL_EXCLUSIONS:
    'copilot:v1:offers.settings.globalexclusions:create',
  CREATE_PRICE_LISTS: 'copilot:v1:offers.settings.pricelists:create',
  CREATE_SEGMENTS: 'copilot:v1:offers.settings.segments:create',
}

export const UPDATE_PERMISSIONS = {
  UPDATE_COUPONS: 'copilot:v1:offers.coupons:update',
  UPDATE_PROMOTIONS: 'copilot:v1:offers.promotions:update',
  UPDATE_GLOBAL_EXCLUSIONS:
    'copilot:v1:offers.settings.globalexclusions:update',
  UPDATE_PRICE_LISTS: 'copilot:v1:offers.settings.pricelists:update',
  UPDATE_SEGMENTS: 'copilot:v1:offers.settings.segments:update',
  UPDATE_ATTRIBUTES: 'copilot:v1:offers.settings.attributes:update',
}

export const READ_PERMISSIONS = {
  READ_COUPONS: 'copilot:v1:offers.coupons:read',
  READ_COUPONS_EXPORT: 'copilot:v1:offers.coupons.export:read',
  READ_PROMOTIONS: 'copilot:v1:offers.promotions:read',
  READ_GLOBAL_EXCLUSIONS: 'copilot:v1:offers.settings.globalexclusions:read',
  READ_PRICE_LISTS: 'copilot:v1:offers.settings.pricelists:read',
  READ_SEGMENTS: 'copilot:v1:offers.settings.segments:read',
  READ_PRICES: 'copilot:v1:offers.prices:read',
  READ_SETTINGS: 'copilot:v1:offers.settings:read',
  READ_OFFERS: 'copilot:v1:offers:read',
  READ_ATTRIBUTES: 'copilot:v1:offers.settings.attributes:read',
}

export const DELETE_PERMISSIONS = {
  DELETE_SEGMENTS: 'copilot:v1:offers.settings.segments:delete',
  DELETE_PRICE_LIST: 'copilot:v1:offers.settings.pricelists:delete',
  DELETE_GLOBAL_EXCLUSIONS:
    'copilot:v1:offers.settings.globalexclusions:delete',
}

export const PERMISSION_ERROR = {
  OFFER_ERROR: "You don't have permission to access offers",
  SECONDARY_TEXT: 'Please request access from an admin',
}

export const fileStatus = {
  IN_PROGRESS: 'In Progress',
  ERROR: 'Error',
  DONE: 'Done',
}

export const STACKING_PRIORTIES = [
  {
    id: 1,
    label: '1 - Highest',
    name: '1 - Highest',
    value: 1,
    priority: 'Highest',
  },
  { id: 2, label: '2 - High', name: '2 - High', value: 2, priority: 'High' },
  {
    id: 3,
    label: '3 - Medium',
    name: '3 - Medium',
    value: 3,
    priority: 'Medium',
  },
  { id: 4, label: '4 - Low', name: '4 - Low', value: 4, priority: 'Low' },
  {
    id: 5,
    label: '5 - Lowest',
    name: '5 - Lowest',
    value: 5,
    priority: 'Lowest',
  },
]

export const isStackable = {
  value: 'Stackable',
  isStackable: true,
  isAlwaysApplied: false,
}

export const isGlobalExclusive = {
  value: 'Exclusive',
  level: 0,
  isStackable: false,
  isAlwaysApplied: false,
}

export const isTypeExclusive = {
  value: 'Type exclusive',
  isStackable: false,
  isAlwaysApplied: false,
}

export const isUniversal = {
  value: 'Universal',
  isStackable: false,
  isAlwaysApplied: true,
  level: 0,
}

export const stackingTypeOptions = [
  {
    name: 'Stackable',
    onSelect: () => isStackable,
  },
  {
    name: 'Exclusive',
    onSelect: () => isGlobalExclusive,
  },
  {
    name: 'Type exclusive',
    onSelect: () => isTypeExclusive,
  },
  {
    name: 'Universal',
    onSelect: () => isUniversal,
  },
]

export const PROMO_TYPE_PRIORITY_MAPPING = {
  SHIPPING: 5,
  CART: 3,
  SPEND_GET: 3,
}

export const DEFAULT_LABEL = 'Select stacking priority'

export const PROMO_STACKING_CONST = {
  heading: 'Promotion stacking',
  EnableStackingText: 'Allow promotion stacking',
  stackingPriority: 'Stacking priority',
  priorityToolTip:
    'Set priority ranking from 1-5. 1 being the highest and applied first and 5 applied last.',
}

export const COUPON_STACKING_CONST = {
  heading: 'Coupon stacking',
  EnableStackingText: 'Allow coupon stacking',
}

export const PRODUCT_TARGET_OPTIONS = [
  {
    id: 'all',
    label: 'All SKUs',
    value: 'All',
    state: '',
    countField: '',
  },
  {
    id: 'SKU',
    label: 'SKUs',
    value: 'SKUs',
    state: 'SelectedSkus',
    countField: 'FinalSelectedSkusCount',
    getFormattedData: (selections) =>
      selections.map((selection) => selection.sku),
  },
  {
    id: 'category',
    label: 'Category',
    value: 'Categories',
    state: 'Products',
    countField: 'FinalCategoriesSkusCount',
    getFormattedData: (products) => {
      const categories = products.find(
        (product) => product.key === 'Categories'
      )
      if (categories) {
        return categories?.values?.map((catgeory) => catgeory.title)
      }
    },
  },
  {
    id: 'collection',
    label: 'Collection',
    value: 'Collections',
    state: 'Products',
    countField: 'FinalCollectionsSkusCount',
    getFormattedData: (products) => {
      const collections = products.find(
        (product) => product.key === 'Collections'
      )
      if (collections) {
        return collections?.values?.map((collection) => collection.title)
      }
    },
  },
  {
    id: 'attribute',
    label: 'Attribute',
    value: 'Attributes',
    state: 'SelectedAttributes',
    countField: 'FinalAttributesSkusCount',
    getFormattedData: (selections) =>
      selections.map((selection) => selection.title),
  },
]

export const CONDITION_OPTIONS = [
  {
    name: 'AND',
  },
  {
    name: 'OR',
  },
]

export const PRODUCT_SELECTION_LABELS = {
  categories: 'Category',
  skus: 'SKUs',
  collections: 'Collection',
  attributes: 'Attribute',
}
