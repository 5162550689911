import React, { Suspense, useEffect } from 'react'
import { Router, Redirect, navigate } from '@reach/router'
import { APP_PAGE_PATHS, FEATURE_FLAGS } from '../config/constants'
import GenericPageLoader from 'src/components/loader'
import { hasPermission } from '../lib/rolePermissions'
import { READ_PERMISSIONS } from 'src/lib/constants/constants'
import { EmptyPage } from 'ds4-beta'
import { isFeatureActive } from '../api/featureFlag'

const ProductListing = React.lazy(() => import('../features/productListing'))
const PriceImportHistory = React.lazy(() =>
  import('../features/priceImportHistory')
)
const ImportHistory = React.lazy(() =>
  import('../features/couponPromoListing/components/ImportHistory')
)
const ProductPricing = React.lazy(() => import('../features/productPricing'))
const ProductVariant = React.lazy(() => import('../features/productVariant'))
const CustomerSegments = React.lazy(() =>
  import('../features/customerSegments')
)
const AdditionalAttributes = React.lazy(() =>
  import('../features/additionalAttributes')
)
const SettingsPriceList = React.lazy(() =>
  import('../features/settingsPriceList')
)
const SettingsGlobalExclusionListing = React.lazy(() =>
  import('../features/settingsGlobalExclusionListing')
)
const GlobalExclusionList = React.lazy(() =>
  import('../features/globalExclusionList')
)
const CouponPromoListing = React.lazy(() =>
  import('../features/couponPromoListing')
)
const CouponPromoFeature = React.lazy(() => import('../features/promo/create'))

const PromoForm = React.lazy(() =>
  import('../features/promoForm')
)
const CouponPromoEdit = React.lazy(() => import('../features/promo/edit'))
const CouponClone = React.lazy(() => import('../features/promo/clone'))
const CouponRedemtions = React.lazy(() =>
  import('../features/couponRedemptionListing')
)
const CouponRedemptionExportListing = React.lazy(() =>
  import('../features/couponRedemptionExportListing')
)

const AppContent = ({ children }) => {
  useEffect(() => {
    //This navigate is to solve https://yottadv.atlassian.net/browse/OFFER-10096
    //the reason for using window.location and not the location passed by reach router
    //to the child component as location prop, is that the location prop is incorrect
    //when we navigate away from Offers to other app and come back to Offers
    navigate(window.location.pathname)
  }, [])
  return (
    <div className='app_root'>
      <main className='d-flex w-100 app_wrapper'>
        <div className='app_children'>{children}</div>
      </main>
    </div>
  )
}

const Loading = () => <GenericPageLoader />

const DS4AppRoutes = () => {
  const promoFormRedesign = isFeatureActive({
    flagName: FEATURE_FLAGS.PROMO_FORM_REDESIGN,
  })
  if (!hasPermission(READ_PERMISSIONS.READ_OFFERS)) {
    return (
      <EmptyPage
        description={`Please request access from an admin`}
        headerText={`You don't have permission to access offers`}
      />
    )
  }
  return (
    <main id='app_root' data-testid='ds4-app'>
      <Suspense fallback={<Loading />}>
        <Router>
          <AppContent path='/'>
            <ProductListing path={APP_PAGE_PATHS.PRODUCT_LISTING} />
            <PriceImportHistory path={APP_PAGE_PATHS.PRICE_IMPORT_HISTORY} />
            <ProductPricing path={APP_PAGE_PATHS.PRICE_DETAILS} />
            <ProductVariant path={APP_PAGE_PATHS.VARIANT_DETAILS} />
            <CustomerSegments path={APP_PAGE_PATHS.CUSTOMER_SEGMENTS} />
            <AdditionalAttributes path={APP_PAGE_PATHS.SETTINGS_ATTRIBUTES} />
            <SettingsGlobalExclusionListing
              path={APP_PAGE_PATHS.SETTINGS_GLOBAL_EXCLUSIONS_LISTING}
            />
            <CouponPromoFeature path={APP_PAGE_PATHS.COUPON_CREATE} />
            {promoFormRedesign ? (
              <PromoForm path={APP_PAGE_PATHS.PROMO_CREATE} />
            ) : (
              <CouponPromoFeature path={APP_PAGE_PATHS.PROMO_CREATE} />
            )}
            <CouponPromoEdit path={APP_PAGE_PATHS.COUPON_UPDATE} />
            {promoFormRedesign ? (
              <PromoForm path={APP_PAGE_PATHS.PROMO_UPDATE} />
            ) : (
              <CouponPromoEdit path={APP_PAGE_PATHS.PROMO_UPDATE} />
            )}
            <CouponClone path={APP_PAGE_PATHS.COUPON_CLONE} />
            <CouponClone path={APP_PAGE_PATHS.PROMO_CLONE} />
            <Redirect
              from={APP_PAGE_PATHS.SETTINGS}
              to={APP_PAGE_PATHS.SETTINGS_PRICE_LIST}
              noThrow
            />
            <SettingsPriceList path={APP_PAGE_PATHS.SETTINGS_PRICE_LIST} />

            <GlobalExclusionList
              path={APP_PAGE_PATHS.CREATE_GLOBAL_EXCLUSIONS_LIST}
              type='create'
            />
            <GlobalExclusionList
              path={APP_PAGE_PATHS.UPDATE_GLOBAL_EXCLUSIONS_LIST}
              type='update'
            />
            <CouponPromoListing path={APP_PAGE_PATHS.ALL_COUPONS} />
            <CouponPromoListing path={APP_PAGE_PATHS.PROMO_LISTING} />
            <CouponRedemtions path={APP_PAGE_PATHS.COUPON_REDEMPTION_LISTING} />
            <CouponRedemptionExportListing
              path={APP_PAGE_PATHS.COUPON_REDEMPTION_EXPORT_LISTING}
            />
            <ImportHistory
              path={APP_PAGE_PATHS.COUPON_IMPORT_HISTORY}
              type='coupon'
            />
            <Redirect
              from='/offers/'
              to={APP_PAGE_PATHS.PRODUCT_LISTING}
              noThrow
            />
            <Redirect
              from={APP_PAGE_PATHS.COUPON_LISTING}
              to={APP_PAGE_PATHS.ALL_COUPONS}
              noThrow
            />
            <Redirect
              from={APP_PAGE_PATHS.OLD_COUPON_CREATE}
              to={APP_PAGE_PATHS.COUPON_CREATE}
              noThrow
            />
            <Redirect
              from={APP_PAGE_PATHS.OLD_COUPON_UPDATE}
              to={APP_PAGE_PATHS.COUPON_UPDATE}
              noThrow
            />
            <Redirect
              from={APP_PAGE_PATHS.OLD_COUPON_CLONE}
              to={APP_PAGE_PATHS.COUPON_CLONE}
              noThrow
            />
            <Redirect
              from={APP_PAGE_PATHS.OLD_COUPON_IMPORT_HISTORY}
              to={APP_PAGE_PATHS.COUPON_IMPORT_HISTORY}
              noThrow
            />
          </AppContent>
        </Router>
      </Suspense>
    </main>
  )
}

export default DS4AppRoutes
